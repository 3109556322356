'use client';

import findComponent from '@next-app/utils/find-component';
import { HeaderNavigationContent } from '@next-app/interface/Navigation';
import classes from './navigation.module.scss';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import Conditional from '@next-app/components/Conditional';

const MobileNavigation = dynamic(() => import('./MobileNavigation'), {
  ssr: true,
});
interface PageContentNavigationProps {
  contentItem: HeaderNavigationContent;
}

const Navigation = ({ contentItem }: PageContentNavigationProps) => {
  const { navigationLeft, navigationRight } = contentItem;
  const NavigationLeftComponent = findComponent(navigationLeft);
  const NavigationRightComponent = findComponent(navigationRight);

  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [isWeb, setIsWeb] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 450) {
        setIsMobile(true);
        setIsTab(false);
        setIsWeb(false);
      }
      if (window.innerWidth >= 450 && window.innerWidth < 992) {
        setIsMobile(false);
        setIsTab(true);
        setIsWeb(false);
      }
      if (window.innerWidth > 992) {
        setIsMobile(false);
        setIsTab(false);
        setIsWeb(true);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!NavigationLeftComponent || !NavigationRightComponent) {
    return null;
  }
  const attributes = {
    s_regionid: 'Nav-Main',
  };

  return (
    <>
      <Conditional if={isMobile || isTab}>
        <MobileNavigation contentItem={contentItem} />
      </Conditional>

      <div>
        <nav
          id="nav"
          className={`hover ${classes.navMenu} d-lg-block d-none`}
          {...attributes}
        >
          <div className="container">
            <div className="tight">
              <div className="nav-left d-flex gap-5 justify-content-center">
                <NavigationLeftComponent
                  contentItem={navigationLeft}
                  mobileNavigation={false}
                />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navigation;
