import findComponent from '@next-app/utils/find-component';
import getUniqueKey from '@next-app/utils/get-unique-key';

const PageSlot = (props: any) => {
  if (!props?.contentItem?.contents?.length) {
    return;
  }

  const SlotComponentList = props?.contentItem?.contents?.map(
    (item: any, index: number) => {
      const DynamicComponent: any = findComponent(item);
      return (
        <DynamicComponent
          contentItem={item}
          key={getUniqueKey(index, item.name)}
        />
      );
    },
  );

  return <>{SlotComponentList}</>;
};

export default PageSlot;
