import { PageTitleContent } from '@next-app/interface/Common';
import classes from './pageTitle.module.scss';
import Conditional from '@next-app/components/Conditional';

interface PageTitleProps {
  contentItem: PageTitleContent;
}

export default function PageTitle(props: PageTitleProps) {
  let rightCopy: string[] = [];
  if (props.contentItem?.rightCopy.length > 0) {
    rightCopy = props.contentItem.rightCopy.split(',');
  }
  return (
    <Conditional if={props.contentItem.enabled}>
      <div className={classes['page-title-container']}>
        <div
          className={`${classes['page-title']} ${
            props.contentItem.displayGrayRule ? classes['gray-rule'] : ''
          }`}
        >
          <h1 className={classes['title-copy']}>
            {props.contentItem.pageTitle}
          </h1>
          <Conditional if={rightCopy.length > 0}>
            <span className={classes['right-copy']}>
              {rightCopy.map((item, index) => {
                return (
                  <>
                    <span>{item}</span>
                    {index !== rightCopy.length - 1 && (
                      <span className={classes['bullet']}>&bull;</span>
                    )}
                  </>
                );
              })}
            </span>
          </Conditional>
        </div>
      </div>
    </Conditional>
  );
}
