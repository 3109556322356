import Link from '@next-app/components/elements/Link/Link';
import findComponent from '@next-app/utils/find-component';
import { FooterCertificationContent } from '@next-app/interface/PageContent';
import OnetrustModal from '@next-app/components/shared/PageContent/FooterCertification/OnetrustModal';
import classes from './footer-certification.module.scss';

interface FooterCertificationProps {
  contentItem: FooterCertificationContent;
}

export default function FooterCertification(props: FooterCertificationProps) {
  const { cerificationIcons } = props.contentItem;
  const CertificationIconsComponent = findComponent(cerificationIcons);

  if (!CertificationIconsComponent) return null;

  return (
    <footer
      id="footer-links"
      className="blueDot"
      role="presentation"
      {...{ s_regionid: 'Footer-Legal - Main' }}
    >
      <div id="legal" className={`${classes.legal}`}>
        <div className={classes.container}>
          <div className={`${classes.footerLinkWrapper} row `}>
            <div className="col-lg-2">
              <ul className="legal-icons display-flex" role="presentation">
                <CertificationIconsComponent contentItem={cerificationIcons} />
              </ul>
            </div>

            <div className="col-lg-2">
              <p className={classes.footerpromo}>
                <span>
                  *
                  <Link
                    href="/coupons/legal/current-promotions"
                    s_objectid="promo-details-footer-link"
                  >
                    View
                  </Link>{' '}
                  promotion details.
                </span>
              </p>
            </div>

            <div className="col-lg-8">
              <div className={classes['footer-policy']}>
                <span>
                  <Link
                    tabIndex={0}
                    s_objectid="privacy-policy-page-footer-link"
                    href="/about/privacy-policy"
                  >
                    Privacy Policy
                  </Link>

                  <span className={`${classes['link-divider']}`}>|</span>

                  <Link
                    s_objectid="children-policy-footer-link"
                    href="/about/privacy-policy-children"
                  >
                    Children&rsquo;s Policy
                  </Link>
                </span>
                <span
                  className={`${classes['link-divider']} ${classes['hidden-xs']}`}
                >
                  |
                </span>
                <span>
                  <Link
                    s_objectid="do-not-sell-info-footer-link"
                    href="/help/preference-center/opt-out-preferences"
                  >
                    Do Not Sell or Share My Personal Information
                  </Link>
                </span>
                <OnetrustModal />
              </div>
            </div>

            <div className="col-xs-12">
              <p
                className={`${classes['copy-right']}`}
                dangerouslySetInnerHTML={{
                  __html:
                    '<sup>&copy;</sup>' +
                    new Date().getFullYear() +
                    ' Lakeshore Learning Materials. All rights reserved.',
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
