import { IChicletsContainer, IChiclets } from '@next-app/interface/PageContent';
import findComponent from '@next-app/utils/find-component';
import getUniqueKey from '@next-app/utils/get-unique-key';
import Image from 'next/image';
import Heading from '@next-app/components/elements/Heading/Heading';
import Conditional from '@next-app/components/Conditional';
import classes from './chiclets-container.module.scss';

const ChicletsContainer = ({
  contentItem,
}: {
  contentItem: IChicletsContainer;
}) => {
  const defaultColors =
    contentItem.defaultColors &&
    contentItem.defaultColors.split(',').map((el) => el.trim());

  const chicletHeight =
    contentItem.height &&
    contentItem.height.split(',').map((item) => item.trim());

  const chicletFontSize =
    contentItem.fontSize &&
    contentItem.fontSize.split(',').map((item) => item.trim());

  const height = {
    xs: chicletHeight[0],
    sm: chicletHeight[1],
    md: chicletHeight[2],
    lg: chicletHeight[3],
  };

  const fontSize = {
    xs: chicletFontSize[0],
    sm: chicletFontSize[1],
    md: chicletFontSize[2],
    lg: chicletFontSize[3],
  };

  const ComponentList = contentItem?.chiclets
    ?.map((item: IChiclets, key: number) => {
      const Component = findComponent(item);
      if (!Component) {
        return null;
      }
      const colors = (defaultColors.length && defaultColors[key]) || '';
      return (
        <div
          className={`${classes[contentItem?.chicletType ? contentItem?.chicletType?.toLocaleLowerCase() + '-chiclet-item' : 'default-chiclet-container-chiclet-item']}`}
          key={getUniqueKey(key, `${item.name}-chiclets`)}
        >
          <Component
            contentItem={item}
            key={getUniqueKey(key, item.name)}
            defaultColors={colors}
            height={height}
            fontSize={fontSize}
            borderRadius={contentItem.borderRadius || '4px'}
            backgroundColor={contentItem.backgroundFlavor}
            chicletType={contentItem?.chicletType}
            textDecoration={contentItem?.textDecoration}
          />
        </div>
      );
    })
    .filter(Boolean);

  const bgColor =
    contentItem.backgroundFlavor && contentItem.backgroundFlavor !== ''
      ? contentItem.backgroundFlavor
      : '';

  return (
    <Conditional if={contentItem.enabled}>
      <div
        className={`${contentItem.fullWidth ? 'full-width-container' : 'page-container'}`}
      >
        <div
          className={`${classes[contentItem?.chicletType ? contentItem?.chicletType?.toLocaleLowerCase() + '-section' : 'default-chiclet-container-section']}
              ${classes['background-flavor']}`}
          style={
            {
              '--bgColor': bgColor,
            } as React.CSSProperties
          }
        >
          <Heading
            tagName="2"
            customClass={`${classes['chicletHeading']} ${bgColor === 'transparent' ? classes['color-black'] : ''}`}
          >
            {contentItem.title}
          </Heading>
          <Conditional if={contentItem?.imageAsset?.length > 0}>
            <div>
              <Image
                src={contentItem?.imageAsset}
                width={100}
                height={100}
                quality={100}
                alt={contentItem?.imageAsset}
              />
            </div>
          </Conditional>
          <Conditional
            if={['rectangle', 'rounded_rectangle'].includes(
              contentItem?.chicletType,
            )}
          >
            <>{ComponentList}</>
          </Conditional>
          <Conditional
            if={
              !['rectangle', 'rounded_rectangle'].includes(
                contentItem?.chicletType,
              )
            }
          >
            <div
              className={`${classes[contentItem?.chicletType ? contentItem?.chicletType?.toLocaleLowerCase() + '-chiclet' : 'default-chiclet-container-chiclet']}`}
            >
              {ComponentList}
            </div>
          </Conditional>
          <Conditional if={contentItem?.imageAsset?.length > 0}>
            <Image
              src={contentItem?.imageAsset}
              width={100}
              height={100}
              quality={100}
              alt={contentItem?.imageAsset}
            />
          </Conditional>
        </div>
      </div>
    </Conditional>
  );
};

export default ChicletsContainer;
