import findComponent from '@next-app/utils/find-component';
import { IMenuItems, ITabData, MenuTabProps } from '@next-app/interface/Common';
import MenuTabHtml from './MenuTabHtml';

const MenuTab = ({ contentItem, mobileNavigation }: MenuTabProps) => {
  const { tabData, tabHeader, name, tabURL } = contentItem;

  function combineMenuItems(data: ITabData[]) {
    const menuItems = data.map((item: ITabData) => {
      if (
        item.groupData &&
        (item.groupData[0].itemType === 'age' ||
          item.groupData[0].itemType === 'grade')
      ) {
        return item.groupData[0].menuItems;
      }
    });

    const updatedMenuItems = menuItems.filter(
      (menuItem) => menuItem !== undefined,
    );
    const newMenuItems = updatedMenuItems.reduce(
      (
        acc: IMenuItems[] | undefined,
        curr: IMenuItems | undefined,
        index: number,
      ) => {
        if (index === 0) {
          return curr;
        }
        if (curr !== undefined && acc !== undefined) {
          return curr.map((item: IMenuItems, i: number) => ({
            displayName: `${acc[i].displayName} (${item.displayName})`,
            type: `${acc[i].type} ${item.type}`,
            url: acc[i].url,
            gradeUrl: item.url,
          }));
        }
      },
      [],
    );

    return newMenuItems;
  }

  const newMenuItemsArray = combineMenuItems(tabData);

  const ComponentList = tabData.map((item: any, index: number) => {
    const Component = findComponent(item);
    if (!Component) return null;

    return (
      <Component
        contentItem={item}
        tabHeader={tabHeader}
        key={index}
        mobileMenuItemsArr={newMenuItemsArray}
      />
    );
  });

  return (
    <MenuTabHtml
      name={name}
      tabHeader={tabHeader}
      tabURL={tabURL}
      ComponentList={ComponentList}
      mobileNavigation={mobileNavigation}
    />
  );
};

export default MenuTab;
