'use client';

import { useEffect, useState } from 'react';
import GenericImage from '../../../elements/GenericImage/GenericImage';
import { useSelector } from 'react-redux';
import { Content } from '@next-app/interface/Common';
import Conditional from '@next-app/components/Conditional';

interface HTMLProps {
  contentItem: Content;
}

interface initState {
  init: {
    init: {
      initResponse: {
        sceneSevenImageUrlPrefix: string;
        scene7BaseURL: string;
        scene7Env: string;
      };
    };
  };
}

const Link = ({ contentItem }: HTMLProps) => {
  const { init } = useSelector((state: initState) => state.init);
  const [imgIcon, setImgIcon] = useState('');
  const { imgAsset, imgAssetFolder } = contentItem || {};

  useEffect(() => {
    if (init.initResponse) {
      const { sceneSevenImageUrlPrefix, scene7BaseURL, scene7Env } =
        init.initResponse;
      const isContentUrl = imgAssetFolder
        ? imgAssetFolder
        : sceneSevenImageUrlPrefix;
      const imgIconurl =
        scene7BaseURL + isContentUrl + '/' + scene7Env + '/' + imgAsset;
      setImgIcon(imgIconurl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init.initResponse]);

  return (
    <div className="footer-badge-wrap">
      <Conditional if={imgIcon ? true : false}>
        <GenericImage
          src={imgIcon}
          width={64}
          height={88}
          alt="70 years Icon"
        ></GenericImage>
      </Conditional>
    </div>
  );
};

export default Link;
