'use client';
import Conditional from '@next-app/components/Conditional';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import dynamic from 'next/dynamic';

const HTMLContentModal = dynamic(
  () =>
    import('@next-app/components/elements/HTMLContentModal/HTMLContentModal'),
  { ssr: false },
);

interface IEyebrowMessage {
  contentItem: {
    linkText2: string;
    linkText1: string;
    openModal2: boolean;
    linkUrl1: string;
    linkUrl2: string;
    openModal1: boolean;
    name: string;
    s_objectId1: string;
    text: string;
    modalContentPath1: string;
    modalContentPath2: string;
    s_objectId2: string;
    linkModalTitle1: string;
    linkModalTitle2: string;
  };
  index: number;
  handleModalCallback: Function;
}

const EyebrowMessage = (props: IEyebrowMessage) => {
  const [showEyebrowModal, setShowEyebrowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const {
    text,
    linkText1,
    linkText2,
    openModal1,
    openModal2,
    linkUrl1,
    linkUrl2,
    modalContentPath1,
    modalContentPath2,
    linkModalTitle1,
    linkModalTitle2,
    s_objectId1,
    s_objectId2,
  } = props.contentItem;

  const modalTitle = linkModalTitle1 !== '' ? linkModalTitle1 : linkModalTitle2;

  function handleModal(url: string) {
    setModalUrl(url);
    setShowEyebrowModal(true);
  }
  function splitStrings() {
    const regex = /\${(\d)}/g;
    const parts = text.split(regex);
    return parts;
  }
  const textArr = splitStrings();

  const href = usePathname();

  return (
    <>
      {textArr.map((textVal: string, key: number) => {
        return (
          <span key={key}>
            <Conditional if={textVal !== '1' && textVal !== '2'}>
              <>{textVal}</>
            </Conditional>
            <Conditional if={textVal === '1'}>
              <>
                <Conditional if={openModal1}>
                  <a
                    data-bs-toggle="modal"
                    href={href}
                    onClick={() => handleModal(modalContentPath1)}
                    data-bs-target={`#dynamicModalWrapper`}
                    {...{ s_objectid: s_objectId1 }}
                  >
                    {linkText1}
                  </a>
                </Conditional>
                <Conditional if={!openModal1}>
                  <a href={linkUrl1} {...{ s_objectid: s_objectId1 }}>
                    {linkText1}
                  </a>
                </Conditional>
              </>
            </Conditional>
            <Conditional if={textVal === '2'}>
              <>
                <Conditional if={openModal2}>
                  <a
                    data-bs-toggle="modal"
                    href={href}
                    onClick={() => handleModal(modalContentPath2)}
                    data-bs-target={`#dynamicModalWrapper`}
                    {...{ s_objectid: s_objectId2 }}
                  >
                    {linkText2}
                  </a>
                </Conditional>
                <Conditional if={!openModal2}>
                  <a href={linkUrl2} {...{ s_objectid: s_objectId2 }}>
                    {linkText2}
                  </a>
                </Conditional>
              </>
            </Conditional>
          </span>
        );
      })}
      <Conditional if={showEyebrowModal}>
        <HTMLContentModal
          url={modalUrl}
          modalTitle={modalTitle}
          setShowEyebrowModal={setShowEyebrowModal}
          modalId="seeDetailModal"
        />
      </Conditional>
    </>
  );
};

export default EyebrowMessage;
