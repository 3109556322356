import Image from 'next/image';
import parse from 'html-react-parser';
import Link from '@next-app/components/elements/Link/Link';
import { BannerProps } from '@next-app/interface/PageContent';
import findComponent from '@next-app/utils/find-component';
import getUniqueKey from '@next-app/utils/get-unique-key';
import ResponsiveImage from '@next-app/components/elements/ResponsiveImage/ResponsiveImage';
import Carouselndicator from './Carouselndicator';
import classes from './banner.module.scss';
import Conditional from '@next-app/components/Conditional';

interface PageContentBanner {
  contentItem: BannerProps;
}

const Banner = ({ contentItem }: PageContentBanner) => {
  const {
    enabled,
    moibileMediaSourceURL,
    mobileMediaSourceURL,
    mediaAlternative,
    tabletMediaSourceURL,
    mediaSourceURL,
    isTabContent,
    lazyload,
    promoTerms,
    carousel,
    contentAlign,
    s_objectId,
    s_regionId,
    mediaFollowURL,
    fullWidth,
    displayControl,
  } = contentItem;

  const isCarousel = contentItem.carousel && contentItem.carousel.length > 0;
  const hasPromoTerms = promoTerms.length > 0;
  const hasMediaSourceURL = mediaSourceURL.length > 0;

  const contentAlignAbove = contentAlign === 'above';
  const contentAlignUnderneath = contentAlign === 'underneath';
  const contentAlignOver = contentAlign === 'over';
  const mobileImgSrc = (
    moibileMediaSourceURL ||
    mobileMediaSourceURL ||
    tabletMediaSourceURL ||
    mediaSourceURL ||
    ''
  ).split('?')[0];

  const mobileImgUrl =
    (mobileImgSrc && `${mobileImgSrc}?${contentItem.mediaPreset3}`) || '';

  const tabImgSrc = (tabletMediaSourceURL || mediaSourceURL || '').split(
    '?',
  )[0];

  const tabImgUrl =
    (tabImgSrc && `${tabImgSrc}?${contentItem.mediaPreset2}`) || '';

  const desktopImgSrc = (contentItem.mediaSourceURL || '').split('?')[0];

  const desktopImgUrl =
    (desktopImgSrc && `${desktopImgSrc}?${contentItem.mediaPreset1}`) || '';

  const isFullUrl = mobileImgUrl.indexOf('https://') < 0;
  const elemAttributes = {
    s_objectid: s_objectId || '',
  };

  return (
    <Conditional if={enabled === true}>
      <div
        className={`pageContent-banner ${fullWidth ? 'full-width-container' : 'page-container'}`}
        {...{ s_regionid: s_regionId || '' }}
      >
        <Conditional if={isTabContent === true}>
          <div className="row mt20 mb20">
            <div className="col-sm-3 mb20" {...elemAttributes}>
              <Image
                className="autofill pt5"
                src={mediaSourceURL}
                alt={mediaAlternative}
                loading={lazyload ? 'lazy' : 'eager'}
                width={1500}
                height={170}
                quality={100}
                priority={lazyload === false}
              />
            </div>
            <div className="col-sm-9">{parse(promoTerms)}</div>
          </div>
        </Conditional>

        <Conditional if={isCarousel}>
          <div
            id="carouselIndicators"
            className={`${classes['home-carousel']} home-carousel-slider slide carousel ${!displayControl ? 'mb-0' : ''}`}
            data-bs-ride="carousel"
            data-bs-pause="false"
          >
            {displayControl && <Carouselndicator contentItem={contentItem} />}
            <div className="carousel-inner">
              {carousel?.map((item: any, index: number) => {
                const Component = findComponent(item);
                if (!Component) return null;
                return (
                  <div
                    key={index}
                    className={`carousel-item ${index == 0 ? 'active' : 0}`}
                  >
                    <Component
                      contentItem={item}
                      contentAlign={contentAlign}
                      key={getUniqueKey(index, 'name')}
                      lazyload={index !== 0}
                    />
                  </div>
                );
              })}
            </div>
            <button
              className={`carousel-control-prev ${classes['previous-prev-icon']}`}
              type="button"
              data-bs-target="#carouselIndicators"
              data-bs-slide="prev"
            >
              <span
                className={`${classes['carousel-control-prev-icon']}`}
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className={`carousel-control-next ${classes['previous-next-icon']}`}
              type="button"
              data-bs-target="#carouselIndicators"
              data-bs-slide="next"
            >
              <span
                className={`${classes['carousel-control-next-icon']}`}
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </Conditional>

        <Conditional if={hasMediaSourceURL}>
          <>
            <Conditional if={hasPromoTerms && contentAlignAbove}>
              <div className="col-xs-12 gsa-promo-terms-above">
                {parse(promoTerms)}
              </div>
            </Conditional>
            <div
              className={`${
                hasPromoTerms
                  ? contentAlignAbove
                    ? 'gsa-promo-banner-above'
                    : contentAlignUnderneath
                      ? 'gsa-promo-banner-under'
                      : `${classes['gsa-heroImage']} gsauser-heroImage p-0 d-lg-block d-md-block`
                  : `${classes['heroImage']} gsauser-heroImage p-0 d-lg-block d-md-block`
              }`}
            >
              <Link
                className="display-block no-decor"
                id={s_objectId}
                href={mediaFollowURL != '#' ? mediaFollowURL : ''}
                {...elemAttributes}
              >
                <ResponsiveImage
                  mobileImagePath={mobileImgUrl}
                  tabletImagePath={tabImgUrl}
                  desktopImagePath={desktopImgUrl}
                  altText={mediaAlternative || ''}
                  width={1500}
                  height={170}
                  isFullUrl={isFullUrl}
                  lazyload={lazyload}
                />
              </Link>
            </div>
            <Conditional if={hasPromoTerms && contentAlignUnderneath}>
              <div className="col-xs-12 gsa-promo-terms-under">
                {parse(promoTerms)}
              </div>
            </Conditional>
          </>
        </Conditional>

        <Conditional if={contentAlignOver}>
          <div
            className="col-xs-12 gsa-promo-terms-over"
            style={{
              backgroundImage: `url(${mediaSourceURL})`,
            }}
          >
            {parse(promoTerms)}
          </div>
        </Conditional>

        <div className="col-xs-12">{parse(promoTerms)}</div>
      </div>
    </Conditional>
  );
};

export default Banner;
