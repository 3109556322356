import Conditional from '@next-app/components/Conditional';
import classes from './textarea.module.scss';

interface TextareaProps {
  labelText?: string | React.JSX.Element | React.JSX.Element[];
  customClass?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  ariaLabel?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  errorText?: string;
  error?: boolean;
  onFocus?: any;
  datatestid?: string;
  rows?: number;
  maxLength?: number;
}

const Textarea: React.FC<TextareaProps> = (props) => {
  const {
    labelText,
    customClass,
    ariaLabel,
    onChange,
    value,
    disabled,
    id,
    name,
    errorText,
    error,
    onFocus,
    datatestid,
    rows,
    maxLength,
    ...rest
  } = props;

  const customclass = `${error ? classes.error : ''}`;

  return (
    <div className={`${classes.textareacontrol} ${customclass} ${customClass}`}>
      {labelText && <label>{labelText}</label>}
      <textarea
        disabled={disabled}
        className="form-control"
        id={id}
        value={value}
        onChange={onChange}
        name={name}
        aria-label={ariaLabel}
        onFocus={onFocus}
        data-testid={datatestid}
        maxLength={maxLength ? maxLength : undefined}
        {...rest}
        rows={rows}
      />
      <i className="fa-solid fa-magnifying-glass"></i>
      {errorText && error && <p>{errorText}</p>}
      <Conditional if={errorText === '' && !!error}>
        <p>{errorText}</p>
      </Conditional>
    </div>
  );
};

export default Textarea;
