import { ContentSlot, Content } from '@next-app/interface/Common';
import findComponent from '@next-app/utils/find-component';
import getUniqueKey from '@next-app/utils/get-unique-key';

interface SlotProps {
  contentItem: ContentSlot;
}

const Slot = ({ contentItem }: SlotProps) => {
  const ComponentList =
    contentItem?.contents?.length > 0 &&
    contentItem?.contents?.map((item: Content, key: number) => {
      const Component = findComponent(item);

      if (!Component) return null;

      return (
        <Component
          contentItem={item}
          key={getUniqueKey(key, item.name)}
          isMobileNav={item.isMobileNav || false}
          mobileMainNavItem={item.mobileMainNavItem || ''}
          resourcePath={
            contentItem?.['endeca:auditInfo']?.['ecr:resourcePath'] || ''
          }
        />
      );
    });

  return ComponentList;
};

export default Slot;
