import CertificationIcons from '@next-app/components/CertificationIcons/CertificationIcons';
import {
  SectionContent,
  FooterCertificationContent,
} from '@next-app/interface/PageContent';
import findComponent from '@next-app/utils/find-component';

interface SectionProps {
  contentItem: SectionContent;
}

const Section = (props: SectionProps) => {
  const { type, content } = props.contentItem;
  let ComponentList: React.ReactElement<any, any>[] = [];
  switch (type) {
    case 'list': {
      const list = content
        .map((item: any, key: number) => {
          const Component = findComponent(item);
          if (!Component) {
            return null;
          }
          return (
            <div className="mr0md" key={key}>
              <Component contentItem={item} />
            </div>
          );
        })
        .filter(Boolean);
      ComponentList = list as React.ReactElement<any, any>[]; // Assign the filtered list to ComponentList
      break;
    }

    case 'tab': {
      break;
    }

    case 'menu': {
      break;
    }

    case 'container': {
      const container = content
        .map((item: FooterCertificationContent, key: number) => {
          const Component = findComponent(item);
          if (!Component) {
            return null;
          }
          return (
            <div className="mr0md" key={key}>
              <Component contentItem={item} />
            </div>
          );
        })
        .filter(Boolean);
      ComponentList = container as React.ReactElement<any, any>[];
      break;
    }

    case 'slider': {
      break;
    }

    case 'masthead': {
      const list = content
        .map((item: FooterCertificationContent, index: number) => {
          const Component = findComponent(item);
          if (!Component) {
            return null;
          }
          return (
            <div className="mr0md" key={`masthead-${index}`}>
              <Component contentItem={item} />
            </div>
          );
        })
        .filter(Boolean);
      ComponentList = list as React.ReactElement<any, any>[];
      break;
    }

    case 'callouts': {
      break;
    }

    case 'buckets': {
      break;
    }

    case 'feature': {
      break;
    }

    case 'sidebar': {
      break;
    }

    case 'section': {
      break;
    }

    case 'freeResources': {
      break;
    }

    case 'json': {
      ComponentList = content
        .map((item: FooterCertificationContent, key: number) => {
          return <CertificationIcons contentItem={item} key={key} />;
        })
        .filter((element) => element !== null) as React.ReactElement<
        any,
        any
      >[];
      break;
    }
    case 'modal':
      ComponentList = content.map((item, key) => {
        const Component: any = findComponent(item);
        return <Component key={key} contentItem={item} />;
      });
      break;
    default: {
      ComponentList = content
        .map((item: FooterCertificationContent, key: number) => {
          const Component = findComponent(item);
          if (!Component) {
            return null;
          }
          return <Component contentItem={item} key={key} />;
        })
        .filter((element) => element !== null) as React.ReactElement<
        any,
        any
      >[];
      break;
    }
  }
  return ComponentList;
};

export default Section;
