'use client';

import { useRef, useState } from 'react';
import classes from './contact-form.module.scss';
import Modal from '@next-app/components/elements/Modal/Modal';
import Conditional from '@next-app/components/Conditional';
import Button from '@next-app/components/elements/Button/Button';
import Textarea from '@next-app/components/elements/Textarea/Textarea';
import { IContactFormModalProps } from '@next-app/interface/ContactForm';
import {
  checkValidation,
  formatPhoneNumber,
  removeSpecialCharacters,
  specialCharactersField,
} from '@next-app/utils/form-validation';
import { useSelector } from 'react-redux';
import Input from '@next-app/components/elements/Input/Input';
import { formDataFields } from '@next-app/constants/formdata';
import { fetchDataWithJsonp } from '@next-app/utils/fethDataJsonP';
import { dyEvent } from '@next-app/utils/dy-event';
import { ContactFormDTMEvent } from '@next-app/utils/dtm-event';
import { SeoItem } from '@next-app/interface/Common';

export interface IpardotData {
  result: 'success' | 'error';
}

const initialErrorState = {
  fname: '',
  lname: '',
  phoneNo: '',
  zipCode: '',
  email: '',
  message: '',
};
const initialFormData = {
  fname: '',
  lname: '',
  phoneNo: '',
  zipCode: '',
  email: '',
  message: '',
};
const ContactForm = ({ contentItem }: IContactFormModalProps) => {
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrorState);
  const { sessionInfo, init, seoData } = useSelector(
    (state: any) => state.init,
  );

  const successRef = useRef<string | Element>('');
  const failedRef = useRef<string | Element>('');
  const requestRef = useRef<string | Element>('');

  if (!Object.keys(sessionInfo).length || !init || !init.initResponse) {
    return;
  }

  const handleOnChange = (value: string, field: string) => {
    if (!specialCharactersField.includes(field)) {
      value = removeSpecialCharacters(value);
    }
    if (field === 'phoneNo') {
      value = formatPhoneNumber(value);
    }
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const callBackFunc = (success: boolean) => {
    const modalInstance = window.bootstrap.Modal.getInstance(
      requestRef.current,
    );
    if (modalInstance) {
      modalInstance.hide();
    }
    if (success) {
      const newModal = new window.bootstrap.Modal(successRef.current);
      newModal.show();
    } else {
      const newModal = new window.bootstrap.Modal(failedRef.current);
      newModal.show();
    }
  };

  const validateform = () => {
    const errors = checkValidation(formData);
    setErrors(errors);
    return Object.values(errors).every((error) => error === '');
  };

  const handleCLose = () => {
    setFormData(initialFormData);
    window.bootstrap.Modal.getInstance(successRef.current)?.hide();
  };

  const getDtmData = (data: SeoItem[]) => {
    const currentUrl = window?.location?.pathname || '/';
    const seoUrl = currentUrl?.split('+')?.length
      ? currentUrl?.split('+')[0]
      : currentUrl;

    const pageData = data?.find((item: SeoItem) => item.url.includes(seoUrl));

    const formName =
      contentItem?.submitAction === 'isPardotForm'
        ? 'Contact a Rep'
        : 'B2B request access';

    return { pageData, formName };
  };

  const handleSubmit = async () => {
    const submitAction = contentItem.isPardotForm
      ? 'isPardotForm'
      : contentItem?.submitAction;
    const dtmData = getDtmData(seoData);
    if (validateform() && submitAction === 'isPardotForm') {
      const formValue = {
        ...formData,
        ['phoneNo']: formData['phoneNo'].replace(/\D/g, ''),
      };
      const queryString = new URLSearchParams(formValue).toString();
      try {
        const endpoint = `${contentItem.apiURL}?${queryString}&formId=${contentItem.formId}&_=${Date.now()}`;
        const callbackFunctionName: string = 'pardotCallBack';
        await fetchDataWithJsonp(
          endpoint,
          callbackFunctionName,
          function (data: IpardotData) {
            if (data.result === 'success') {
              dyEvent({
                eventName: 'Identify',
                type: 'identify-v1',
                email: formData.email,
              });
              callBackFunc(true);
            } else if (data.result === 'error') {
              callBackFunc(false);
            }
          },
        );
        ContactFormDTMEvent({
          event: 'submit',
          details: {
            pageTitle: dtmData?.pageData?.title || '',
            pageName:
              dtmData?.pageData?.pageName || dtmData?.pageData?.title || '',
            llmAPIVersion: init?.initResponse?.internalAPI,
            isFurniture: false,
            linkText: 'Submit',
            formName: dtmData?.formName,
            name: 'submit',
            pageSection: 'category',
            pageType: 'category',
          },
        });
      } catch (error) {
        callBackFunc(false);
      }
    }
  };

  const handleClose = (event: string) => {
    setErrors(initialErrorState);
    const dtmData = getDtmData(seoData);
    ContactFormDTMEvent({
      event: event,
      details: {
        pageTitle: dtmData?.pageData?.title || '',
        pageName: dtmData?.pageData?.pageName || dtmData?.pageData?.title || '',
        llmAPIVersion: init?.initResponse?.internalAPI,
        isFurniture: false,
        linkText: event,
        formName: dtmData?.formName,
        name: event,
        pageSection: 'category',
        pageType: 'category',
      },
    });
  };

  return (
    <>
      <Modal
        showHeader={true}
        id="contactForm-failure"
        dialogClass={`modal-dialog ${classes['dialogModal']}`}
        contentClass={`modal-content ${classes['modalContent']}`}
        headingTagName="4"
        footerClass="modal-footer"
        modalTitle={contentItem.modalSuccessTitle}
        wrapperClass={`${classes['requestMoreInformation']}`}
        showSecondaryButton={true}
        titleClass={classes['modalTitle']}
        closeModalOnClick={() => setErrors(initialErrorState)}
        ref={failedRef}
      >
        {contentItem.errorMessage}
      </Modal>
      <Modal
        showHeader={true}
        id="contactForm-success"
        dialogClass={`modal-dialog ${classes['dialogModal']}`}
        contentClass={`modal-content ${classes['modalContent']}`}
        headingTagName="4"
        footerClass="modal-footer"
        modalTitle={contentItem.modalSuccessTitle || 'Thank You'}
        wrapperClass={`${classes['requestMoreInformation']}`}
        showSecondaryButton={true}
        titleClass={classes['modalTitle']}
        closeModalOnClick={() => setErrors(initialErrorState)}
        ref={successRef}
      >
        <div className={`${classes['success-content']}`}>
          {contentItem.modalSuccessMessage}
        </div>
        <div className={`${classes['contactForm-closeBtn']}`}>
          <Button variant="ternary" onClick={handleCLose}>
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        showHeader={true}
        id="contactForm-request"
        dialogClass={`modal-dialog ${classes['dialogModal']}`}
        contentClass={`modal-content ${classes['modalContent']}`}
        headingTagName="4"
        footerClass="modal-footer"
        modalTitle={contentItem.formTitle}
        wrapperClass={`${classes['requestMoreInformation']}`}
        showPrimaryButton={true}
        showSecondaryButton={true}
        primaryButtonName="Submit"
        titleClass={classes['modalTitle']}
        closeModalOnClick={() => handleClose('close')}
        ref={requestRef}
      >
        <p className={classes['subTitle']}>{contentItem.copyText}</p>
        <div className="row gx-3">
          <div className="col-12 col-sm-6">
            <div
              className={`${classes['input-wrapper']} ${errors?.fname.length > 0 ? classes['has-error'] : ''}`}
            >
              <Input
                data-testid="first-name"
                type={formDataFields.fname.type}
                onChange={(e) =>
                  handleOnChange(
                    e.target.value,
                    formDataFields.fname.propertyName,
                  )
                }
                labelText={formDataFields.fname.label}
                maxLength={formDataFields.fname.maxLength}
                disabled={!contentItem.enabled}
                value={formData.fname}
                customClass={classes['inputField']}
              />
              <Conditional if={errors?.fname.length > 0}>
                <div className={`${classes['error-msg']}`}>{errors?.fname}</div>
              </Conditional>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div
              className={`${classes['input-wrapper']} ${errors?.lname.length > 0 ? classes['has-error'] : ''}`}
            >
              <Input
                data-testid="last-name"
                type={formDataFields.lname.type}
                onChange={(e) =>
                  handleOnChange(
                    e.target.value,
                    formDataFields.lname.propertyName,
                  )
                }
                labelText={formDataFields.lname.label}
                maxLength={formDataFields.lname.maxLength}
                disabled={!contentItem.enabled}
                value={formData.lname}
                customClass={classes['inputField']}
              />
              <Conditional if={errors?.lname.length > 0}>
                <div className={`${classes['error-msg']}`}>{errors?.lname}</div>
              </Conditional>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div
              className={`${classes['input-wrapper']} ${errors?.phoneNo.length > 0 ? classes['has-error'] : ''}`}
            >
              <Input
                data-testid="phoneNo"
                type={formDataFields.phoneNo.type}
                onChange={(e) =>
                  handleOnChange(
                    e.target.value,
                    formDataFields.phoneNo.propertyName,
                  )
                }
                labelText={formDataFields.phoneNo.label}
                maxLength={parseInt(formDataFields.phoneNo.maxLength)}
                disabled={!contentItem.enabled}
                value={formData.phoneNo}
                customClass={classes['inputField']}
              />
              <Conditional if={errors?.phoneNo.length > 0}>
                <div className={`${classes['error-msg']}`}>
                  {errors?.phoneNo}
                </div>
              </Conditional>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div
              className={`${classes['input-wrapper']} ${errors?.zipCode.length > 0 ? classes['has-error'] : ''}`}
            >
              <Input
                data-testid="zip-code"
                type={formDataFields.zipCode.type}
                onChange={(e) =>
                  handleOnChange(
                    e.target.value,
                    formDataFields.zipCode.propertyName,
                  )
                }
                labelText={formDataFields.zipCode.label}
                maxLength={formDataFields.zipCode.maxLength}
                disabled={!contentItem.enabled}
                value={formData.zipCode}
                customClass={classes['inputField']}
              />
              <Conditional if={errors?.zipCode.length > 0}>
                <div className={`${classes['error-msg']}`}>
                  {errors?.zipCode}
                </div>
              </Conditional>
            </div>
          </div>
          <div className="col-12">
            <div
              className={`${classes['input-wrapper']} ${errors?.email.length > 0 ? classes['has-error'] : ''}`}
            >
              <Input
                data-testid="email"
                type={formDataFields.email.type}
                onChange={(e) =>
                  handleOnChange(
                    e.target.value,
                    formDataFields.email.propertyName,
                  )
                }
                labelText={formDataFields.email.label}
                maxLength={formDataFields.email.maxLength}
                disabled={!contentItem.enabled}
                value={formData.email}
                customClass={classes['inputField']}
              />
              <Conditional if={errors?.email.length > 0}>
                <div className={`${classes['error-msg']}`}>{errors?.email}</div>
              </Conditional>
            </div>
          </div>
          <div className="col-12">
            <div
              className={`${classes['input-wrapper']} ${errors?.message.length > 0 ? classes['has-error'] : ''}`}
            >
              <Textarea
                data-testid="message"
                rows={5}
                onChange={(e) =>
                  handleOnChange(
                    e.target.value,
                    formDataFields.message.propertyName,
                  )
                }
                labelText={formDataFields.message.label}
                customClass={classes['textareaField']}
                value={formData.message}
                maxLength={250}
              />
              <Conditional if={errors?.message.length > 0}>
                <div className={`${classes['error-msg']}`}>
                  {errors?.message}
                </div>
              </Conditional>
            </div>
          </div>
          <div className={classes['fields-required']}>
            All fields are required
          </div>
          <div className="col-12">
            <div className="d-md-flex gap-3 order-md-2 justify-content-end mt-3">
              <Button
                customClass={`order-md-2 ${classes['dailogBtn']}`}
                data-testid="submit-button-create"
                onClick={handleSubmit}
                {...{ s_objectid: contentItem?.s_objectid }}
              >
                {contentItem.submitBtnText}
              </Button>
              <Button
                customClass={`${classes['dailogBtn']}`}
                onClick={() => handleClose('cancel')}
                variant="ternary"
                data-bs-dismiss="modal"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ContactForm;
