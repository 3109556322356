'use client';

import Link from '@next-app/components/elements/Link/Link';
import classes from './footer-certification.module.scss';
import { setActivityMapLink } from '@next-app/utils/set-adobe-activity-map';
import { useEffect } from 'react';

export default function OnetrustModal() {
  useEffect(() => {
    const handleFirstMovement = (event: MouseEvent) => {
      const consentDiv = document.getElementById('onetrust-consent-sdk');
      const bannerDiv = document.getElementById('onetrust-banner-sdk');
      if (consentDiv && !consentDiv.contains(event.target as Node)) {
        document.removeEventListener('click', handleFirstMovement);
        if (bannerDiv) {
          bannerDiv.classList.add('hidden');
        }
        const closeElem = document.querySelector('.banner-close-button');
        if (closeElem) {
          (closeElem as HTMLElement).click();
        }
      }
    };

    document.addEventListener('click', handleFirstMovement);

    return () => {
      document.removeEventListener('click', handleFirstMovement);
    };
  }, []);

  const doNotShareClick = (event: any) => {
    event.preventDefault();
    const consentDiv = document.getElementById('onetrust-consent-sdk');
    if (consentDiv) {
      consentDiv.style.display = 'block';
      const otHideRemoveFromFilter = document.querySelector(
        '.onetrust-pc-dark-filter',
      );
      const otHideRemoveFromPcSDK = document.getElementById('onetrust-pc-sdk');
      if (otHideRemoveFromFilter && otHideRemoveFromPcSDK) {
        otHideRemoveFromFilter.removeAttribute('style');
        otHideRemoveFromPcSDK.removeAttribute('style');
        otHideRemoveFromFilter.classList.remove('ot-hide');
        otHideRemoveFromPcSDK.classList.remove('ot-hide');
        otHideRemoveFromFilter.setAttribute('style', 'height: 100vh;');
        otHideRemoveFromPcSDK.setAttribute('style', 'height: 700px;');
      }
    }
  };

  return (
    <div>
      <span className={`${classes['link-divider']} ${classes['hidden-xs']}`}>
        |
      </span>
      <span>
        <Link
          data-testid="footer-ot-show-settings1"
          href="/about/privacy-policy"
          data-redirect={false}
          id="footer-ot-show-settings1"
          s_objectid="do-not-share-info-footer-link"
          key="Open_Do_Not_Share_My_Personal_Data"
          tabIndex={0}
          onClick={(event) => {
            doNotShareClick(event);
            setActivityMapLink(event.target);
          }}
        >
          Targeted Advertising Opt Out
        </Link>
      </span>
    </div>
  );
}
