import { HeaderBlockContent } from '@next-app/interface/Header';
import { MenuItem, NavigationRight } from '@next-app/interface/Navigation';
import classes from './account-menu.module.scss';
import findComponent from '@next-app/utils/find-component';
import getUlClassNames from '@next-app/utils/get-ul-classnames';
import getUniqueKey from '@next-app/utils/get-unique-key';

type DynamicComponentType = React.ComponentType<{
  contentItem: HeaderBlockContent;
}>;

interface AccountMenuProps {
  contentItem: NavigationRight;
  menuItems?: MenuItem[];
  accMenuType?: string;
}

export default function AccountMenu({
  contentItem,
  accMenuType,
}: AccountMenuProps) {
  const ComponentList = contentItem.menuItems.map(
    (item: MenuItem, index: number) => {
      const DynamicComponent = findComponent(item) as DynamicComponentType;
      return (
        <DynamicComponent
          contentItem={item}
          key={getUniqueKey(index, item.name)}
        />
      );
    },
  );

  const ulClassNames = getUlClassNames(contentItem) || '';
  const accMenuNavClass =
    accMenuType === 'NavRight' ? classes['mobile-menu-right'] : '';
  return (
    <ul
      className={`${accMenuNavClass} ${ulClassNames} ${classes['reward-card']}`}
    >
      {ComponentList}
    </ul>
  );
}
