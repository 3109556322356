'use client';

import { useEffect, useState } from 'react';
import Conditional from '@next-app/components/Conditional';
import {
  getRecentSearch,
  getRecentlyViewedProduct,
  setRecentSearch,
  removeRecentSearchTerm,
} from '@next-app/utils/recent-searches';
import classes from './search.module.scss';
import { dyEvent } from '@next-app/utils/dy-event';
import { useRouter } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { ProductsList } from './ProductsList';
import Link from '@next-app/components/elements/Link/Link';
import closeIcon from '/public/assets/images/x-close-light.svg';
import Button from '@next-app/components/elements/Button/Button';
import Image from 'next/image';
import {
  selectDynSess,
  selectIsGSAUser,
} from '@next-app/lib/features/InitSelectors';
import { fetchRecsProductInfo } from '@next-app/utils/common-utils';
import { SearchProps } from './Search.interface';
import { SEARCH_SUGGESTIONS_COUNT } from '@next-app/constants/constants';

const RecentSearch: React.FC<SearchProps> = ({
  keyPressedEvent,
  handleCloseModal,
}) => {
  const recentlyViewedProductMaxCount = 4;
  const router = useRouter();
  const dispatch = useDispatch();
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const [recentSearchesList, setRecentSearchesList] = useState<string[]>([]);
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const isGSAUser = useSelector(selectIsGSAUser);

  const viewedProducts = getRecentlyViewedProduct(isGSAUser).reverse();
  const viewedProductIds = viewedProducts.map(
    (product: { id: string }) => product.id,
  );
  const dynSessConfNumber = useSelector(selectDynSess);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 787);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * fetchProductsData
   */
  const fetchProductsData = async () => {
    try {
      if (viewedProductIds.length > 0) {
        let productsData = await fetchRecsProductInfo(
          viewedProductIds.toString(),
          '',
          '',
          dispatch,
          dynSessConfNumber,
          recentlyViewedProductMaxCount,
        );
        const maxProduct = productsData[0]?.recsProductInfo?.slice(0, 4);
        setRecentlyViewedProducts(maxProduct || []);
      }
    } catch (error) {
      console.error('Error occurred in fetching recently visited products');
      setRecentlyViewedProducts([]);
    }
  };

  // on load
  useEffect(() => {
    fetchProductsData();
    setRecentSearchesList(getRecentSearch().reverse());
  }, []);

  // invoke handleKeyDown if keypressed event occurs
  useEffect(() => {
    if (keyPressedEvent) {
      handleKeyDown(keyPressedEvent);
    }
  }, [keyPressedEvent]);

  /**
   * handleHighlightedIndex
   * @param arrowDirection
   */
  const handleHighlightedIndex = (arrowDirection: 'up' | 'down') => {
    const recentLength = recentSearchesList.length;
    switch (arrowDirection) {
      case 'up': {
        setHighlightedIndex((prevIndex: number) => {
          const val =
            prevIndex === -1
              ? recentLength - 1
              : (prevIndex - 1 + recentLength) % recentLength;
          if (isNaN(val)) return -1;
          return val;
        });
        break;
      }

      case 'down': {
        setHighlightedIndex((prevIndex: number) => {
          const val = (prevIndex + 1) % recentLength;
          if (isNaN(val)) return -1;
          return val;
        });
        break;
      }

      default: {
        // unsupported key pressed
        break;
      }
    }
  };

  /**
   * handleKeyDown
   * @param e
   */
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      handleHighlightedIndex('down');
    } else if (e.key === 'ArrowUp') {
      handleHighlightedIndex('up');
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      e.preventDefault();
      const selectedSuggestion = recentSearchesList[highlightedIndex];
      if (selectedSuggestion) {
        const properties = {
          dyType: 'keyword-search-v1',
          keywords: selectedSuggestion,
        };
        dyEvent({ properties: properties, eventName: 'Keyword Search' });
        setRecentSearch(selectedSuggestion);
        router.push(`${'/search/products/?Ntt='}${selectedSuggestion}`);
      }
    }
  };

  /**
   * onRemoveRecentItem
   * @param keyword
   */
  const onRemoveRecentItem = (keyword: string) => {
    setTimeout(() => {
      removeRecentSearchTerm(keyword);
      setRecentSearchesList(getRecentSearch().reverse());
    }, 100);
  };

  return (
    <Conditional
      if={recentSearchesList.length > 0 || recentlyViewedProducts.length > 0}
    >
      <div
        className={`${classes.searchWrapper} ${classes['recent-search-wrapper']}`}
      >
        <div className={`row m-0`}>
          <>
            <Conditional if={recentSearchesList.length > 0}>
              <div
                className={`col-12 ${
                  recentlyViewedProducts.length > 0
                    ? isMobileView || window.innerWidth < 992
                      ? 'col-12'
                      : 'col-md-6'
                    : ''
                } ${classes.suggestionBorder} ${
                  recentlyViewedProducts.length > 0 ? classes.bottomBorder : ''
                }`}
              >
                <h5 className="fw-bold">Recent Searches:</h5>
                <div className={classes['recent-searches-item']}>
                  {(isMobileView
                    ? recentSearchesList.slice(0, SEARCH_SUGGESTIONS_COUNT)
                    : recentSearchesList
                  ).map((recentSearch, index) => (
                    <div
                      key={index}
                      id={index.toString()}
                      className={`${index === highlightedIndex ? classes['tt-cursor'] : ''} ${classes['recentItem']}`}
                    >
                      <Link
                        href={`${'/search/products/?Ntt='}${recentSearch}`}
                        onClick={handleCloseModal as (event: any) => void}
                      >
                        <span className={`${classes['item-title']}`}>
                          {recentSearch}
                        </span>
                      </Link>
                      <Button
                        customClass={`${classes['closeArrow']}`}
                        onClick={() => {
                          onRemoveRecentItem(recentSearch);
                        }}
                        variant="transparent"
                      >
                        <Image
                          src={closeIcon}
                          alt="Close Arrow"
                          unoptimized={true}
                        />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </Conditional>
            <Conditional
              if={recentlyViewedProducts && recentlyViewedProducts.length > 0}
            >
              <div
                className={`col-12 d-md-block ${recentSearchesList.length > 0 ? 'col-md-6' : 'border-0'} ${classes['product-suggestions']} ${classes['recent-v-product-suggestions']}`}
              >
                <h5 className="fw-bold">Recently Viewed Products:</h5>
                <ProductsList
                  products={recentlyViewedProducts}
                  isRecentResults={true}
                  customClass={'recently-view-product'}
                  handleCloseModal={handleCloseModal}
                />
              </div>
            </Conditional>
          </>
        </div>
      </div>
    </Conditional>
  );
};

export default RecentSearch;
