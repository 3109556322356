import { SpacerContent } from '@next-app/interface/Common';
import classes from './spacer.module.scss';
import Conditional from '@next-app/components/Conditional';

interface SpacerProps {
  contentItem: SpacerContent;
}

export default function Spacer(props: SpacerProps) {
  return (
    <Conditional if={props.contentItem.enabled}>
      <div className={classes[`spacer-${props.contentItem.spacerSize}`]}></div>
    </Conditional>
  );
}
