'use client';

import { useState } from 'react';
import { SubscribeFormContent } from '@next-app/interface/PageContent';
import Input from '@next-app/components/elements/Input/Input';
import Button from '@next-app/components/elements/Button/Button';
import ErrorMessage from '@next-app/components/elements/ErrorMessage/ErrorMessage';
import getSetSessionPreferences from '@next-app/utils/get-set-sessions-preferences';
import { validateEmail } from '@next-app/utils/generic-functions';
import Heading from '@next-app/components/elements/Heading/Heading';
import classes from './subscribe-form.module.scss';
import Conditional from '@next-app/components/Conditional';

interface SubscribeFormProps {
  contentItem: SubscribeFormContent;
}

const SubscribeForm = ({ contentItem }: SubscribeFormProps) => {
  const { name, description = '', placeholder } = contentItem;
  const communicationPrefEnabled = true;
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isError, setIsError] = useState(false);

  const signUpPreFilledEmail = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email.length === 0) {
      setError(() => {
        return 'Please enter an email address.';
      });
      setIsError(true);
    } else if (communicationPrefEnabled) {
      const validEmail = validateEmail(email);

      if (validEmail) {
        getSetSessionPreferences({
          email,
        });
        window.location.href = '/help/preference-center?subscribe-email=true';
        setIsError(false);
      } else if (!validEmail) {
        setError(() => {
          return 'Please enter a valid email address.';
        });
        setIsError(true);
      }
    } else {
      window.location.href = '/help/communication-prefs';
    }
  };

  return (
    <div className={`col-md-12 ${classes['subscribe-form']}`}>
      <Heading tagName="3" customClass={classes['subscribeHeading']}>
        {name}
      </Heading>

      <div
        dangerouslySetInnerHTML={{ __html: description }}
        className={classes['sign-up-h']}
      ></div>
      <form
        className={classes['footer-email']}
        data-testid="footer-email-form"
        noValidate
        onSubmit={(event) => signUpPreFilledEmail(event)}
      >
        <Input
          name="footer-email"
          id="footer-email"
          type="email"
          value={email}
          labelText="Enter your Email address"
          labelForScreenReader={true}
          placeholder={placeholder}
          aria-label={placeholder}
          data-testid="footer-email-input"
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setEmail(e.target.value)
          }
          customClass={`${classes['subscribe-input']}`}
          error={isError}
        />

        <div
          className={`ll-btn-container align-right inline ${classes['subscribe-cta']}`}
        >
          <Button
            type="submit"
            id="btnJoin"
            customClass={`btn-sld-blue ll-btn-sm btn-cta ${classes.joinBtn}`}
            data-testid="footer-join-button"
          >
            Join
          </Button>
        </div>
      </form>
      <Conditional if={error.length > 0}>
        <ErrorMessage customClass={classes['error-message']}>
          {error}
        </ErrorMessage>
      </Conditional>
      <div className={classes['text-terms']}>
        <p>
          <span>
            <a href="/help/mobile-opt-in/">Sign up</a> for exclusive text offers
            and deals.
          </span>
        </p>
        <p>
          <span>
            <a className={classes['blue-link']} href="/about/privacy-policy/">
              See Privacy Policy
            </a>{' '}
          </span>
        </p>
      </div>
    </div>
  );
};

export default SubscribeForm;
