import classes from './product-recs-HTML.module.scss';
import Conditional from '@next-app/components/Conditional';

interface CarouselPricingDisplayProps {
  atcRecsEnabled: boolean;
  hasGSAPrice: boolean;
  hasSpecialPrice: boolean;
  isOnGSA: boolean;
  isOnSale: boolean;
  listPriceRange: boolean | string;
  salePriceRange: string | null;
}

const CarouselPricingDisplay = (props: CarouselPricingDisplayProps) => {
  const {
    hasGSAPrice,
    hasSpecialPrice,
    isOnGSA,
    isOnSale,
    listPriceRange,
    salePriceRange,
  } = props;

  return (
    <p className={classes['price']}>
      {!hasSpecialPrice && (
        <>
          {/* List Price */}
          {!isOnSale && !hasGSAPrice && !hasSpecialPrice && (
            <span
              className={`${listPriceRange == 'FREE' ? classes.red : classes.black}`}
            >
              {listPriceRange}
            </span>
          )}

          {/* Sale price */}
          {isOnSale && !hasGSAPrice && (
            <span>
              <span className={classes.red}>{salePriceRange}</span>
              <del>reg.&nbsp;{listPriceRange}</del>
            </span>
          )}

          {/* GSA Price */}
          {hasGSAPrice && (
            <>
              {!isOnSale && (
                <span className={isOnGSA ? classes.green : classes.black}>
                  {salePriceRange}
                </span>
              )}
              {isOnSale && (
                <span className={classes.green}>{salePriceRange}</span>
              )}
              <span className={classes.green}>GSA</span>
            </>
          )}
        </>
      )}

      {/* Special Price */}
      <Conditional if={hasSpecialPrice}>
        <>
          <span className={classes.red}>{}</span>
          <del>reg.&nbsp;{listPriceRange}</del>
        </>
      </Conditional>
    </p>
  );
};

export default CarouselPricingDisplay;
