'use client';

import { useRouter } from 'next/navigation';
import { useEffect, useState, useRef } from 'react';
import { searchSubmit } from './helper';
import classes from './search.module.scss';
import Button from '../../../elements/Button/Button';
import { useSelector } from 'react-redux';
import { SearchProps, RedirectSearchSubmit } from '@next-app/interface/Common';
import Conditional from '@next-app/components/Conditional';
import { dyEvent } from '@next-app/utils/dy-event';
import RecentSearch from './RecentSearch';
import Suggestions from './Suggestions';
import { setRecentSearch } from '@next-app/utils/recent-searches';
import { selectFeatureToggles } from '@next-app/lib/features/InitSelectors';
import { ButtonNames } from '@next-app/constants/constants';

interface SearchState {
  inputVal: string;
  showSuggestions: boolean;
  showRecents: boolean;
  error: false;
}

export default function Search(props: SearchProps) {
  const { placeholder } = props.contentItem;
  const [searchState, setSearchState] = useState<SearchState>({
    inputVal: '',
    showSuggestions: false,
    showRecents: false,
    error: false,
  });
  const router = useRouter();
  const [isError, setError] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isKeyPressed, setIsKeyPressed] =
    useState<React.KeyboardEvent<Element>>();

  const featureToogle = useSelector(selectFeatureToggles);
  const enhancedTypeAheadEnabled =
    featureToogle?.enhancedTypeAheadEnabled ?? false;

  /**
   * Reads search term from url bar and sets it in search input field
   */
  const setDefaultSearchTerm = () => {
    const searchParams: URLSearchParams = new URLSearchParams(
      window.location.search,
    );
    const searchText = searchParams.get('Ntt') || '';
    setSearchState({
      ...searchState,
      inputVal: searchText,
    });
  };

  useEffect(() => {
    document.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      // if user has clicked within the search form, do not blur
      if (
        (target.closest('form')?.classList.contains('header-search') &&
          target.id !== 'search-backdrop' &&
          !target.closest('a')) ||
        (target.tagName === 'BUTTON' &&
          (target as HTMLInputElement).name === ButtonNames.SEARCH_INPUT_REMOVE)
      ) {
        return;
      } else {
        onBlurHandler();
      }
    });
    return () => {
      // document.removeEventListener('click', () => onFocusHandler);
    };
  }, []);

  /**
   * Opaque background enable
   */
  useEffect(() => {
    if (
      enhancedTypeAheadEnabled == true &&
      (searchState.showSuggestions || searchState.showRecents)
    ) {
      document.body.classList.add('show-opaque');
      window.addEventListener('scroll', onBlurHandler);
    }
    return () => {
      document.body.classList.remove('show-opaque');
      window.removeEventListener('scroll', onBlurHandler);
    };
  }, [searchState.showSuggestions || searchState.showRecents]);

  /**
   * hides both suggestions and recents panels
   */
  const hideSearchFlyout = () => {
    setSearchState((prevState) => ({
      ...prevState,
      showSuggestions: false,
      showRecents: false,
      error: false,
    }));
    handleCloseModal();
  };

  /**
   * onBlurHandler
   */
  const onBlurHandler = () => {
    setTimeout(() => {
      hideSearchFlyout();
    }, 200);
  };

  /**
   * Below methods are executed once on component render
   */
  useEffect(() => {
    setDefaultSearchTerm();
  }, []);

  /**
   * setInputValue
   * @param newVal
   */
  const setInputValue = (newVal: string) => {
    setSearchState((prevState) => ({ ...prevState, inputVal: newVal }));
  };

  /**
   * handleInputChange
   * @param e
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target.value;
    const showRecents = inputVal === '' && enhancedTypeAheadEnabled;
    const showSuggestions = inputVal !== '' || !enhancedTypeAheadEnabled;

    setSearchState((prevState) => ({
      ...prevState,
      inputVal,
      showRecents,
      showSuggestions,
    }));
  };

  /**
   * handleClear
   */
  const handleClear = () => {
    setSearchState((prevState) => ({
      ...prevState,
      inputVal: '',
      showRecents: enhancedTypeAheadEnabled === true,
      showSuggestions: enhancedTypeAheadEnabled !== true,
      error: false,
    }));
    inputRef.current?.focus();
  };

  // handleCloseModal

  const handleCloseModal = () => {
    document.body.classList.remove(
      'enhance-mobile-search',
      classes['search-mobile'],
    );
  };

  /**
   * handleKeyDown
   * @param e
   */
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event && ['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
      setIsKeyPressed(event);
    }

    if (event.key === 'Escape') {
      hideSearchFlyout();
    }
  };

  /**
   * handleSubmit
   * @param FormEvent
   * @returns
   */
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputVal = searchState.inputVal?.trim() || '';
    if (!inputVal) {
      setError(true);
      return;
    }

    sessionStorage.setItem('searchDtm', 'internalSearch');
    setRecentSearch(inputVal.trim());

    const properties = {
      dyType: 'keyword-search-v1',
      keywords: inputVal,
    };
    dyEvent({
      properties,
      eventName: 'Keyword Search',
    });

    setSearchState((prevState) => ({
      ...prevState,
      showSuggestions: false,
    }));

    const response: RedirectSearchSubmit | null = await searchSubmit(inputVal);

    if (
      response?.['@type'] == 'RedirectAwareContentInclude' &&
      response?.link
    ) {
      const linkURL = response.link.split('Ntt=');
      router.push(
        linkURL.length == 1
          ? linkURL[0]
          : linkURL[0] + 'Ntt=' + encodeURIComponent(linkURL[1]),
      );
    }
    handleCloseModal();
  };

  /**
   * onFocusHandler
   */
  const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const { inputVal } = searchState;

    // in case of clicking cross button in input box. setSearchState is handled in handleClear()
    if (
      (event.relatedTarget as HTMLInputElement)?.name ===
      ButtonNames.SEARCH_INPUT_REMOVE
    )
      return;

    if (
      inputVal === '' &&
      inputRef.current?.value === '' &&
      enhancedTypeAheadEnabled
    ) {
      setSearchState((prevState) => ({
        ...prevState,
        showRecents: true,
        showSuggestions: false,
      }));
    } else {
      setSearchState((prevState) => ({
        ...prevState,
        showRecents: false,
        showSuggestions: true,
      }));
    }
    if (enhancedTypeAheadEnabled) {
      document.body.classList.add(
        'enhance-mobile-search',
        classes['search-mobile'],
      );
    }
  };

  /**
   * handleInputValidation
   * @param e
   */
  const handleInputValidation = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    const sanitizedInput = input.replace(/[^a-zA-Z0-9 !&'?"\-.,]/g, '');
    if (input !== sanitizedInput) {
      e.currentTarget.value = sanitizedInput;
      setSearchState((prevState) => ({
        ...prevState,
        inputVal: sanitizedInput,
      }));
    }
  };

  return (
    <div className={`${classes.searchFilter}`}>
      <form className="pc-search header-search" onSubmit={handleSubmit}>
        <label htmlFor="search" className={classes['sr-only']}>
          Search:
        </label>
        <span className="ll-typeahead">
          <div className={classes.inputWrap}>
            <input
              id="search"
              name="search"
              type="text"
              ref={inputRef}
              data-testid="search-product-input"
              value={searchState.inputVal}
              className={`${classes.inputSearch} ${isError ? classes.error : ''} form-control`}
              autoCapitalize="none"
              autoComplete="off"
              {...(enhancedTypeAheadEnabled === true && {
                'aria-label': 'Search',
                autoCorrect: 'off',
                spellCheck: false,
              })}
              placeholder={placeholder}
              automation-id="search_input"
              onInput={handleInputValidation}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={onFocusHandler}
              onPaste={() =>
                sessionStorage.setItem(
                  'searchDtm',
                  'internalSearchTermCopyPaste',
                )
              }
            />
          </div>
          <Conditional if={searchState.showSuggestions}>
            <Suggestions
              inputVal={searchState.inputVal}
              keyPressedEvent={isKeyPressed}
              setInputVal={setInputValue}
              enhancedTypeAheadEnabled={enhancedTypeAheadEnabled}
            />
          </Conditional>
          <Conditional if={searchState.showRecents}>
            <RecentSearch
              inputVal={searchState.inputVal}
              keyPressedEvent={isKeyPressed}
              setInputVal={setInputValue}
              handleCloseModal={handleCloseModal}
            />
          </Conditional>
          <Conditional if={enhancedTypeAheadEnabled === true}>
            <div
              id={'search-backdrop'}
              className={`h-100 position-fixed w-100 ${classes['search-backdrop']}`}
            ></div>
          </Conditional>
          <Button
            customClass={`ll-typeahead-remove header-link ls-icon ${classes['icn-x-close-lgt']} ${classes['mobile-enhance-cross-icon']}`}
            aria-label="Remove modal"
            onClick={handleCloseModal}
            variant="transparent"
          ></Button>
          <Conditional if={!!searchState.inputVal}>
            <Button
              customClass={`ll-typeahead-remove header-link ls-icon ${classes['icn-x-close-lgt']}`}
              aria-label="Remove search input"
              onClick={handleClear}
              variant="transparent"
              name={ButtonNames.SEARCH_INPUT_REMOVE}
            ></Button>
          </Conditional>
          <Button
            id="btnSearch"
            customClass={`${classes['search-icon']} ls-icon`}
            aria-live="polite"
            type="submit"
            data-testid="search-product-button"
            automation-id="search_button"
            variant="transparent"
          ></Button>
        </span>
      </form>
    </div>
  );
}
