import { IMicroBanner } from '@next-app/interface/PageContent';
import Button from '@next-app/components/elements/Button/Button';
import classes from './micro-banner.module.scss';
import Link from '@next-app/components/elements/Link/Link';
import Conditional from '@next-app/components/Conditional';
import dynamic from 'next/dynamic';
import ResponsiveImage from '@next-app/components/elements/ResponsiveImage/ResponsiveImage';
const SeeDetailsLinkModal = dynamic(
  () =>
    import(
      '@next-app/components/elements/SeeDetailsLinkModal/SeeDetailsLinkModal'
    ),
  { ssr: false },
);
interface MicroBannerProps {
  contentItem: IMicroBanner;
}

const MicroBanner = (props: MicroBannerProps) => {
  const { contentItem } = props;

  const isFullUrl = contentItem?.mobileAsset.indexOf('https://') < 0;
  const elemAttributes = {
    s_objectid: contentItem?.s_objectId || '',
  };

  const responsiveImageElement = (
    <ResponsiveImage
      isFullUrl={isFullUrl}
      mobileImagePath={contentItem.mobileAsset}
      tabletImagePath={contentItem.tabletAsset}
      desktopImagePath={contentItem.webAsset}
      altText={contentItem.imgAltText}
      fill={true}
      imgAssetFolder={contentItem.imgAssetFolder}
      lazyload={!!contentItem.lazyload}
      className="position-static"
    />
  );

  return (
    <Conditional if={contentItem.enabled}>
      <div
        className={
          contentItem.fullWidth ? 'full-width-container' : 'page-container'
        }
        {...{ s_regionid: contentItem?.s_regionId || '' }}
      >
        <div
          className={`px-3 d-flex flex-sm-row flex-column justify-content-center align-items-center 
              ${contentItem.backgroundFlavor && contentItem.bannerText ? classes['micro-banner-bg-color'] : ''} ${classes.mircoBannerMain}
              ${contentItem?.webAsset?.length ? classes['mircoBanner-bg-Main'] : ''}
              `}
          style={
            {
              '--microBannerColor': contentItem?.backgroundFlavor ?? '',
            } as React.CSSProperties
          }
        >
          <Conditional
            if={
              contentItem?.webAsset?.length > 0 &&
              contentItem?.bannerText?.length === 0
            }
          >
            <div className={classes['imaga-wrapper']}>
              <Conditional if={contentItem?.linkOpenModal === true}>
                <SeeDetailsLinkModal
                  url={contentItem?.linkModalContentURL || ''}
                  className={`${classes.mircoLink} ${classes.seeDetails}`}
                  title={contentItem.linkModalTitle || ''}
                  modalId="microBannerSeeDetailsModal"
                  s_objectId={contentItem?.s_objectId}
                  s_regionId={contentItem?.s_regionId}
                  triggerElement={responsiveImageElement}
                />
              </Conditional>
              <Conditional if={!contentItem?.linkOpenModal}>
                {responsiveImageElement}
              </Conditional>
            </div>
          </Conditional>

          <Conditional
            if={
              contentItem?.webAsset?.length > 0 &&
              contentItem?.bannerText?.length > 0 &&
              contentItem.buttonText.length > 0
            }
          >
            <div className={classes['image-text-button']}>
              {responsiveImageElement}
            </div>
          </Conditional>

          <Conditional
            if={
              contentItem?.webAsset?.length > 0 &&
              contentItem?.bannerText?.length === 0
            }
          >
            <div className={classes['image-btn-main']}>
              <Conditional if={contentItem.buttonText.length > 0}>
                <span
                  className={`ll-btn-container inline ${classes.buttonText}`}
                >
                  <Button
                    customClass={`ll-btn-container inline ${classes.microBtn}`}
                    link={contentItem.buttonURL}
                    style={
                      {
                        '--buttonColor': contentItem?.buttonColor,
                      } as React.CSSProperties
                    }
                    {...elemAttributes}
                  >
                    {contentItem.buttonText}
                  </Button>
                </span>
              </Conditional>
              <Conditional if={contentItem.linkText.length > 0}>
                <span>
                  <Link
                    className={`${classes.mircoLink}`}
                    href={contentItem.linkURL}
                    {...elemAttributes}
                  >
                    {contentItem.linkText}
                  </Link>
                </span>
              </Conditional>
            </div>
          </Conditional>

          <Conditional if={contentItem.bannerText.length > 0}>
            <div
              className={`align-items-center text-center ${classes.microPromoText}`}
            >
              {contentItem.bannerText}
            </div>
          </Conditional>
          {/* banner button */}
          <Conditional
            if={
              contentItem.buttonText.length > 0 &&
              contentItem?.webAsset?.length === 0
            }
          >
            <div className={`ll-btn-container inline ${classes.buttonText}`}>
              <Button
                customClass={`ll-btn-container inline ${classes.microBtn}`}
                link={contentItem.buttonURL}
                style={
                  {
                    '--buttonColor': contentItem?.buttonColor,
                  } as React.CSSProperties
                }
                {...elemAttributes}
              >
                {contentItem.buttonText}
              </Button>
            </div>
          </Conditional>

          <Conditional
            if={
              contentItem.buttonText.length > 0 &&
              contentItem?.webAsset?.length > 0 &&
              contentItem?.bannerText?.length > 0
            }
          >
            <div className={`ll-btn-container inline ${classes.buttonText}`}>
              <Button
                customClass={`ll-btn-container inline ${classes.microBtn}`}
                link={contentItem.buttonURL}
                {...elemAttributes}
                style={
                  {
                    '--buttonColor': contentItem?.buttonColor,
                  } as React.CSSProperties
                }
              >
                {contentItem.buttonText}
              </Button>
            </div>
          </Conditional>
          {/* open modal link */}
          <Conditional
            if={
              contentItem.linkText.length && contentItem?.linkOpenModal
                ? true
                : false
            }
          >
            <div className="d-none d-lg-block">
              <SeeDetailsLinkModal
                modalId="microBannerSeeDetailsModal"
                className={`${classes.mircoLink} ${classes.seeDetails}`}
                linkText={contentItem.linkText}
                url={contentItem?.linkModalContentURL || ''}
                title={contentItem.linkModalTitle || ''}
                s_objectId={contentItem?.s_objectId}
                s_regionId={contentItem?.s_regionId}
              />
            </div>
          </Conditional>
          {/* redirect link */}
          <Conditional
            if={contentItem.linkText.length > 0 && !contentItem?.linkOpenModal}
          >
            <Link
              className={`${classes.mircoLink}`}
              href={contentItem.linkURL}
              {...elemAttributes}
            >
              {contentItem.linkText}
            </Link>
          </Conditional>
        </div>
      </div>
    </Conditional>
  );
};

export default MicroBanner;
