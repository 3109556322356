'use client';

import Conditional from '@next-app/components/Conditional';
import classes from './menu-tab.module.scss';
import getDevice from '@next-app/components/HOC/GetDevice';
import { useState } from 'react';
import { closeAllSelectDropdowns } from '@next-app/utils/common-utils';
import dynamic from 'next/dynamic';
import { MenuTabHtmlProps } from './MobileMenuTab.interface';

const MobileMenuTabHtml = dynamic(() => import('./MobileMenuTabHtml'));

const MenuTabHtml = (props: MenuTabHtmlProps) => {
  const {
    tabHeader,
    ComponentList,
    name,
    tabURL,
    isMobile,
    isTab,
    isWeb,
    mobileNavigation,
  } = props;
  const [hoverClass, setHoverClass] = useState('');

  const adobeAttribute = JSON.stringify({
    vars: { events: 'event24', eVar: 'eVar1', list1: tabHeader },
  });

  return (
    <>
      <Conditional if={isWeb}>
        <>
          <li
            className={`${classes.dropdown} ${hoverClass} navigation-list`}
            onFocus={() => {
              setHoverClass('active');
              closeAllSelectDropdowns();
            }}
            onMouseOver={() => {
              setHoverClass('active');
              closeAllSelectDropdowns();
            }}
            onMouseLeave={() => {
              setHoverClass('');
            }}
            onBlur={() => setHoverClass('')}
          >
            <Conditional if={tabURL ? true : false}>
              <a
                automation-id={`${tabHeader}${mobileNavigation ? ' - Mobile' : ''}`}
                href={tabURL}
                {...{ s_objectid: tabHeader }}
                data-activity-map={adobeAttribute}
              >
                {tabHeader}
              </a>
            </Conditional>

            <Conditional if={!tabURL}>
              <span
                automation-id={`${tabHeader}${mobileNavigation ? ' - Mobile' : ''}`}
                {...{ s_objectid: tabHeader }}
                data-activity-map={adobeAttribute}
              >
                {tabHeader}
              </span>
            </Conditional>
            <div className={classes['nav-dropdown']}>
              <div className="container">
                <div className="row justify-content-center">
                  {ComponentList}
                </div>
              </div>
            </div>
          </li>
        </>
      </Conditional>
      <Conditional if={isMobile || isTab}>
        <MobileMenuTabHtml {...props} />
      </Conditional>
    </>
  );
};

export default getDevice(MenuTabHtml);
