/**
 * An object containing the breakpoints for different screen sizes.
 * @typedef {Object} Breakpoints
 * @property {string} MOBILE - The breakpoint for mobile devices.
 * @property {string} TABLET - The breakpoint for tablet devices.
 * @property {string} DESKTOP - The breakpoint for desktop devices.
 */

/**
 * The breakpoints for different screen sizes.
 * @type {Breakpoints}
 */
export const BREAKPOINTS = {
  /**
   * The breakpoint for mobile devices.
   * @type {string}
   */
  MOBILE: '320px',

  /**
   * The breakpoint for tablet devices.
   * @type {string}
   */
  TABLET: '768px',

  /**
   * The breakpoint for desktop devices.
   * @type {string}
   */
  DESKTOP: '1024px',
};

export const DEFAULT_BREAKPOINTS = {
  Mobile: 320,
  Tablet: 768,
  Desktop: 1024,
};
