import classes from './flyout.module.scss';

interface FlyoutProps {
  children: React.ReactNode;
  customClass?: string;
  id?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Flyout: React.FC<FlyoutProps> = ({ children, customClass, ...rest }) => {
  return (
    <div className={`${classes.popOverContent}  ${customClass}`} {...rest}>
      {children}
    </div>
  );
};

export default Flyout;
