import { ChicletProps } from '@next-app/interface/PageContent';
import classes from './chiclet.module.scss';
import Conditional from '@next-app/components/Conditional';
import ResponsiveImage from '@next-app/components/elements/ResponsiveImage/ResponsiveImage';
import Link from '@next-app/components/elements/Link/Link';

const Chiclet = ({
  contentItem,
  chicletType,
  defaultColors,
  borderRadius,
  fontSize,
  textDecoration = false,
}: ChicletProps) => {
  const {
    chickletColor,
    chickletHoverColor,
    chicletTextColor = defaultColors,
  } = contentItem;

  const chickletStyle = {
    '--chickletColor': chickletColor || defaultColors,
    '--chickletHoverColor': chickletHoverColor
      ? chickletHoverColor
      : chickletColor || defaultColors,
    '--chicletBorderRadius': borderRadius || 0,
    '--chicletFontSize': fontSize || '23px',
    '--chicletMobileFontSize':
      typeof fontSize === 'object' && fontSize.xs ? fontSize.xs : '15px',
    '--chicletTabletFontSize':
      typeof fontSize === 'object' && fontSize.md ? fontSize.md : '14px',
  } as React.CSSProperties;

  const textColor = {
    '--chicletTextColor': chicletTextColor,
  } as React.CSSProperties;

  const elemAttributes = {
    s_objectid: contentItem?.s_objectId || '',
  };

  const Image = (
    <ResponsiveImage
      altText={contentItem.imageAltText}
      desktopImagePath={contentItem.imageSrc}
      isFullUrl={true}
      className="position-relative"
      fill={true}
      unoptimized={true}
    />
  );

  switch (chicletType) {
    case 'rounded_rectangle':
      return (
        <Conditional if={contentItem.enabled}>
          <div
            className={classes.rounded_rectangle}
            {...{ s_regionid: contentItem?.s_regionId || '' }}
          >
            <Link
              className={`${classes['text-color']} ${classes['chiclet-link']}`}
              style={chickletStyle}
              href={contentItem.linkURL}
              {...elemAttributes}
            >
              {contentItem.chicletText && (
                <span className={classes['chiclet-text']}>
                  {contentItem.chicletText}
                </span>
              )}
              {contentItem.subTitle && (
                <span className={classes['chiclet-sub-text']}>
                  {contentItem.subTitle}
                </span>
              )}
            </Link>
          </div>
        </Conditional>
      );
    case 'rectangle':
      return (
        <Conditional if={contentItem.enabled}>
          <div
            className={`${classes.rectangle} ${classes['chiclet-link']}`}
            style={chickletStyle}
            {...{ s_regionid: contentItem?.s_regionId || '' }}
          >
            <Link
              className="no-u white"
              href={contentItem.linkURL}
              {...elemAttributes}
            >
              {contentItem.chicletText && (
                <span className={classes['chiclet-text']}>
                  {contentItem.chicletText}
                </span>
              )}
              {contentItem.subTitle && (
                <span className={classes['chiclet-sub-text']}>
                  {contentItem.subTitle}
                </span>
              )}
            </Link>
          </div>
        </Conditional>
      );
    case 'large_rectangle':
      return (
        <Conditional
          if={contentItem.enabled}
          {...{ s_regionid: contentItem?.s_regionId || '' }}
        >
          <div
            className={`${classes.rectangle} ${classes['large-rectangle']} ${classes['chiclet-link']} ${textDecoration ? classes['no-decoration'] : ''}`}
            style={chickletStyle}
          >
            <Link className="no-u white" href={contentItem.linkURL}>
              {contentItem.chicletText && (
                <span className={classes['chiclet-text']}>
                  {contentItem.chicletText}
                </span>
              )}
              {contentItem.chicletSubtext && (
                <span className={classes['chiclet-sub-text']}>
                  {contentItem.chicletSubtext}
                </span>
              )}
            </Link>
          </div>
        </Conditional>
      );
    case 'circle':
      return (
        <Conditional
          if={contentItem.enabled}
          {...{ s_regionid: contentItem?.s_regionId || '' }}
        >
          <div
            className={`${classes.circle} ${classes['chiclet-link']}`}
            style={chickletStyle}
            title={contentItem?.imageAltText}
            {...elemAttributes}
          >
            <Link
              className={`no-u ${classes['age-limit']} ${classes['text-color']}`}
              href={contentItem.linkURL}
              style={textColor}
              {...elemAttributes}
            >
              {contentItem.chicletText}
            </Link>
            <Link
              className={`no-u ${classes['month-year']} ${classes['text-color']}`}
              href={contentItem.linkURL}
              style={textColor}
              {...elemAttributes}
            >
              {contentItem.subTitle}
            </Link>
            {contentItem.underline && <div className={classes.underline}></div>}
          </div>
        </Conditional>
      );
    case 'circleWithBorder':
      return (
        <Conditional if={contentItem.enabled}>
          <div
            className={`${classes.circle} ${classes.circlewithborder} ${classes['chiclet-link-border']}`}
            style={chickletStyle}
            title={contentItem?.imageAltText}
            {...{ s_regionid: contentItem?.s_regionId || '' }}
          >
            <Link
              className={`no-u ${classes['age-limit']} ${classes['text-color']}`}
              href={contentItem.linkURL}
              style={textColor}
              {...elemAttributes}
            >
              {contentItem.chicletText}
            </Link>
            <Link
              className={`no-u ${classes['month-year']} ${classes['text-color']}`}
              href={contentItem.linkURL}
              style={textColor}
              {...elemAttributes}
            >
              {contentItem.chicletSubtext}
            </Link>
            {contentItem.underline && <div className={classes.underline}></div>}
          </div>
        </Conditional>
      );
    case 'image':
      return (
        <Conditional if={contentItem.enabled}>
          <div
            className={classes['image-item']}
            {...{ s_regionid: contentItem?.s_regionId || '' }}
          >
            <Link
              className={`${classes['subTitle']} no-u white`}
              href={contentItem.linkURL}
              {...elemAttributes}
            >
              {contentItem.chicletText && (
                <span className={classes['chiclet-text']}>
                  {contentItem.chicletText}
                </span>
              )}
              {contentItem.subTitle && (
                <span className={classes['chiclet-sub-title']}>
                  {contentItem.subTitle}
                </span>
              )}
            </Link>
            <Conditional if={contentItem.imageSrc.length > 0}>
              <div {...elemAttributes}>
                <Conditional if={contentItem.chicletSubtext.length > 0}>
                  <Link href={contentItem.linkURL} {...elemAttributes}>
                    {Image}
                  </Link>
                </Conditional>
                <Conditional if={!(contentItem.chicletSubtext.length > 0)}>
                  <Link href={contentItem.linkURL} {...elemAttributes}>
                    {Image}
                  </Link>
                </Conditional>
              </div>
            </Conditional>
            {contentItem.chicletSubtext && (
              <Link
                href={contentItem.linkURL}
                style={textColor}
                {...elemAttributes}
                className={`
                    ${contentItem.chicletTextColor && classes['text-color']}
                    ${classes['image-bottom-text']}
                  `}
              >
                {contentItem.chicletSubtext}
              </Link>
            )}
          </div>
        </Conditional>
      );
    default:
      return (
        <Conditional if={contentItem.enabled}>
          <Link
            className={classes['default-item']}
            href={contentItem.linkURL}
            {...{ s_regionid: contentItem?.s_regionId || '' }}
            {...elemAttributes}
          >
            {contentItem.chicletText && (
              <span className="chiclet-text">{contentItem.chicletText}</span>
            )}
            {contentItem.subTitle && (
              <span className="chiclet-sub-title">{contentItem.subTitle}</span>
            )}
            <Conditional if={contentItem.imageSrc.length > 0}>
              {Image}
            </Conditional>

            <span
              style={textColor}
              className={`
                  ${contentItem.chicletTextColor ? classes['text-color'] : ''}
                  ${classes['default-item-text']}
                  `}
            >
              {contentItem.chicletSubtext}
            </span>
          </Link>
        </Conditional>
      );
  }
};

export default Chiclet;
