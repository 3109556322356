import getInitData from '@next-app/utils/get-init-data';
import { BREAKPOINTS } from '@shared/constants';
import Image from 'next/image';
import { preload } from 'react-dom';
import { JSX } from 'react';
import { isNotNullOrUndefinedorEmpty } from '@next-app/utils/common-utils';
import Conditional from '@next-app/components/Conditional';
import { ResponsiveImageProps } from './ResponsiveImage.interface';
import { checkForArtDirectionProblem } from './ResponsiveImage.utils';

/**
 * Renders a responsive image component based on the provided image paths and other props.
 *
 * @param {ResponsiveImageProps} props - The props object containing the following properties:
 *   - mobileImagePath: The path to the mobile image.
 *   - tabletImagePath: The path to the tablet image.
 *   - desktopImagePath: The path to the desktop image.
 *   - imagePath: The path to the default image.
 *   - altText: The alternative text for the image.
 *   - className: The CSS class name for the image.
 *   - otherProps: Any other additional props to be spread onto the Image component.
 * @return {JSX.Element} The JSX element representing the responsive image component.
 */
export default async function ResponsiveImage({
  mobileImagePath,
  tabletImagePath,
  desktopImagePath,
  altText,
  className,
  isFullUrl = false,
  lazyload = true,
  imgAssetFolder,
  unoptimized = false,
  ...otherProps
}: ResponsiveImageProps): Promise<Awaited<JSX.Element>> {
  const init = await getInitData('force-cache');
  const isEligibleForPictureElement = checkForArtDirectionProblem([
    mobileImagePath,
    tabletImagePath,
    desktopImagePath,
  ]);
  let mobileUrl = mobileImagePath;
  let tabUrl = tabletImagePath;
  let webUrl = desktopImagePath;
  let assetFolder;
  let scene7Env;
  let scene7BaseURL;

  if (init.initResponse && isFullUrl) {
    assetFolder = init.initResponse.sceneSevenImageUrlPrefix;
    scene7Env = init.initResponse.scene7Env;
    scene7BaseURL = init.initResponse.scene7BaseURL;
    const imgBaseUrl = `${scene7BaseURL}${imgAssetFolder ? imgAssetFolder : assetFolder}/${scene7Env}`;
    mobileUrl = `${imgBaseUrl}/${mobileImagePath}`;
    tabUrl = `${imgBaseUrl}/${tabletImagePath}`;
    webUrl = `${imgBaseUrl}/${desktopImagePath}`;
  }

  if (isEligibleForPictureElement && lazyload === false) {
    if (isNotNullOrUndefinedorEmpty(mobileImagePath) && mobileUrl) {
      preload(mobileUrl, { as: 'image' });
    }
    if (isNotNullOrUndefinedorEmpty(tabletImagePath) && tabUrl) {
      preload(tabUrl, { as: 'image' });
    }
    if (isNotNullOrUndefinedorEmpty(webUrl) && webUrl) {
      preload(webUrl, { as: 'image' });
    }
  }

  return (
    <>
      <Conditional if={isEligibleForPictureElement}>
        <picture>
          <source
            media={`(min-width: ${BREAKPOINTS.DESKTOP})`}
            srcSet={webUrl}
          ></source>
          <source
            media={`(min-width: ${BREAKPOINTS.TABLET})`}
            srcSet={tabUrl}
          ></source>
          <source
            media={`(min-width: ${BREAKPOINTS.MOBILE})`}
            srcSet={mobileUrl}
          ></source>
          <img
            alt={altText}
            className={className}
            loading={lazyload === false ? 'eager' : 'lazy'}
            fetchPriority={lazyload === false ? 'high' : 'low'}
            {...otherProps}
          />
        </picture>
      </Conditional>

      <Conditional if={!isEligibleForPictureElement}>
        <Image
          src={webUrl || tabUrl || mobileUrl || 'not-found'} // single source url
          alt={altText}
          className={className}
          loading={lazyload === false ? 'eager' : 'lazy'}
          priority={lazyload === false}
          quality={100}
          sizes="100vw"
          {...(unoptimized === true && { unoptimized: true })}
          {...otherProps}
        />
      </Conditional>
    </>
  );
}
