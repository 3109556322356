'use client';

import findComponent from '@next-app/utils/find-component';
import classes from './HeaderEyebrow.module.scss';
import { useSelector } from 'react-redux';
import { ReactElement, useEffect, useRef, useState } from 'react';
import Conditional from '@next-app/components/Conditional';
import deviceType from '@next-app/utils/get-device-type';

interface IHeaderEyebrowMessage {
  '@type': string;
  linkText2: string;
  linkText1: string;
  openModal2: boolean;
  linkUrl1: string;
  linkUrl2: string;
  openModal1: boolean;
  name: string;
  s_objectId1: string;
  text: string;
  modalContentPath1: string;
  modalContentPath2: string;
  s_objectId2: string;
}

interface IHeaderEyebrow {
  contentItem: {
    messages: Array<IHeaderEyebrowMessage>;
  };
  SkeletonComponent: ReactElement<any, any>;
}

const HeaderEyebrow = (props: IHeaderEyebrow) => {
  const [messages, setMessages] = useState(props.contentItem.messages);
  const { headerFooter, sessionInfo } = useSelector((state: any) => state.init);

  const isGsaUser = sessionInfo?.sessionInfo?.profile?.gsaUser;

  const [height, setHeight] = useState(0);
  const [userDevice, setUserDevice] = useState('');
  const carouselRef = useRef<any>(null);
  const listItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerFooter && !Object.keys(headerFooter).length) {
      return;
    }

    headerFooter?.headerBlock?.contents[0]?.content?.map(
      (item: {
        '@type': string;
        headerEyebrow: [{ contents: [{ messages: IHeaderEyebrowMessage[] }] }];
      }) => {
        if (item['@type'] === 'PageContent-SectionHeader') {
          if (
            item?.headerEyebrow[0]?.contents[0]?.messages &&
            item?.headerEyebrow[0]?.contents[0]?.messages.length > 0
          ) {
            setMessages(item?.headerEyebrow[0]?.contents[0]?.messages);
          }
        }
      },
    );
  }, [headerFooter]);

  useEffect(() => {
    if (messages && messages.length > 1) {
      setTimeout(() => {
        new window.bootstrap.Carousel('#headerEyeBrowCarousel');
      }, 3000);
    }
  }, [messages]);

  useEffect(() => {
    setUserDevice(deviceType());

    let verticalPadding = 12;

    if (listItemRef.current) {
      const listItems = listItemRef.current.children;
      let max = 0;
      Array.from(listItems).forEach((item: any) => {
        const originalDisplay = item.style.display;

        item.style.display = 'block';

        const itemHeight = item.getBoundingClientRect().height;

        if (itemHeight > max) {
          max = itemHeight;
        }
        item.style.display = originalDisplay;
      });
      setHeight(max + verticalPadding);
    }
  }, [
    messages &&
      messages.length > 1 &&
      headerFooter &&
      Object.keys(headerFooter).length > 0,
  ]);

  let ComponentList;

  if (messages && messages.length > 0) {
    ComponentList = messages.map((item: IHeaderEyebrowMessage, key: number) => {
      const Component = findComponent(item);
      if (!Component) return null;
      return (
        <div className={`carousel-item ${key === 0 ? 'active' : ''}`} key={key}>
          <p>
            <Component contentItem={item} key={key} index={key} />
          </p>
        </div>
      );
    });
  }

  return (
    <>
      <Conditional if={!isGsaUser}>
        <div
          className={`hideHeaderElem ${classes.headerEye}`}
          style={{
            height: userDevice === 'mobile' ? ` ${height}px` : '',
          }}
        >
          <Conditional if={headerFooter && !Object.keys(headerFooter).length}>
            <>{props.SkeletonComponent}</>
          </Conditional>
          <Conditional
            if={
              messages &&
              messages.length > 1 &&
              headerFooter &&
              Object.keys(headerFooter).length > 0
            }
          >
            <div
              id="headerEyeBrowCarousel"
              className={`carousel carousel-dark slide ${classes['eyebrow-carousel']} eyebrow-slider`}
              data-bs-ride="carousel"
              data-bs-pause="false"
            >
              <div
                className={`carousel-inner ${classes['eyebrow-carousel-items']}`}
                ref={listItemRef}
              >
                {ComponentList}
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#headerEyeBrowCarousel"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#headerEyeBrowCarousel"
                data-bs-slide="next"
                ref={carouselRef}
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </Conditional>
          <Conditional
            if={
              messages &&
              messages.length === 1 &&
              headerFooter &&
              Object.keys(headerFooter).length > 0
            }
          >
            <>{ComponentList}</>
          </Conditional>
        </div>
      </Conditional>
    </>
  );
};

export default HeaderEyebrow;
