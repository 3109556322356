import PageOneColumn from '../components/shared/PageOneColumn/PageOneColumn';
import ContentSlot from '../components/shared/ContentSlot/ContentSlot';

import { Media } from '@next-app/components/shared/CarouselContent';
import { ComponentType } from 'react';
import AccountMenu from '@next-app/components/shared/PageContent/AccountMenu/AccountMenu';
import AccountMenuLink from '@next-app/components/shared/PageContent/AccountMenuLink/AccountMenuLink';
import AllCategoryNavigationMenu from '@next-app/components/shared/PageContent/AllCategoryNavigationMenu/AllCategoryNavigationMenu';
import Banner from '@next-app/components/shared/PageContent/Banner/Banner';
import PageContentButton from '@next-app/components/shared/PageContent/Button/Button';
import Chiclet from '@next-app/components/shared/PageContent/Chiclet/Chiclet';
import ChicletsContainer from '@next-app/components/shared/PageContent/ChicletsContainer/ChicletsContainer';
import ContactForm from '@next-app/components/shared/PageContent/ContactForm/ContactForm';
import ContactFormField from '@next-app/components/shared/PageContent/ContactFormField/ContactFormField';
import DynamicCategoriesNavigationMenu from '@next-app/components/shared/PageContent/DynamicCategoriesNavigationMenu/DynamicCategoriesNavigationMenu';
import EyebrowMessage from '@next-app/components/shared/PageContent/EyebrowMessage/EyebrowMessage';
import FeaturedSlot from '@next-app/components/shared/PageContent/FeaturedSlot/FeaturedSlot';
import FooterCertification from '@next-app/components/shared/PageContent/FooterCertification/FooterCertification';
import FooterLinkBlock from '@next-app/components/shared/PageContent/FooterLinkBlock/FooterLinkBlock';
import FooterLinkColumn from '@next-app/components/shared/PageContent/FooterLinkColumn/FooterLinkColumn';
import FullWidthImageWithButtons from '@next-app/components/shared/PageContent/FullWidthImageWithButtons/FullWidthImageWithButtons';
import ImageRow from '@next-app/components/shared/PageContent/ImageRow/ImageRow';
import LakeshoreIcon from '@next-app/components/shared/PageContent/LakeshoreIcon/LakeshoreIcon';
import LinkFooter from '@next-app/components/shared/PageContent/LinkFooter/LinkFooter';
import MainMenu from '@next-app/components/shared/PageContent/MainMenu/MainMenu';
import MenuGroup from '@next-app/components/shared/PageContent/MenuGroup/MenuGroup';
import MenuGroupSlot from '@next-app/components/shared/PageContent/MenuGroupSlot/MenuGroupSlot';
import MenuItem from '@next-app/components/shared/PageContent/MenuItem/MenuItem';
import MenuLink from '@next-app/components/shared/PageContent/MenuLink/MenuLink';
import MenuTab from '@next-app/components/shared/PageContent/MenuTab/MenuTab';
import MenuTabSlot from '@next-app/components/shared/PageContent/MenuTabSlot/MenuTabSlot';
import MicroBanner from '@next-app/components/shared/PageContent/MicroBanner/MicroBanner';
import MLink from '@next-app/components/shared/PageContent/MLink/MLink';
import Navigation from '@next-app/components/shared/PageContent/Navigation/Navigation';
import PageSlot from '@next-app/components/shared/PageContent/PageSlot/PageSlot';
import ProductRecs from '@next-app/components/shared/PageContent/ProductRecs/ProductRecs';
import RowImage from '@next-app/components/shared/PageContent/RowImage/RowImage';
import Search from '@next-app/components/shared/PageContent/Search/Search';
import Section from '@next-app/components/shared/PageContent/Section/Section';
import SectionFooter from '@next-app/components/shared/PageContent/SectionFooter/SectionFooter';
import SectionHeader from '@next-app/components/shared/PageContent/SectionHeader/SectionHeader';
import SectionHTMLTag from '@next-app/components/shared/PageContent/SectionHTMLTag/SectionHTMLTag';
import Slot from '@next-app/components/shared/PageContent/Slot/Slot';
import SocialIcons from '@next-app/components/shared/PageContent/SocialIcons/SocialIcons';
import StrictCategoryListingTitle from '@next-app/components/shared/PageContent/StrictCategoryListing/StrictCategoryListingTitle';
import SubscribeForm from '@next-app/components/shared/PageContent/SubscribeForm/SubscribeForm';
import ViewMoreText from '@next-app/components/shared/PageContent/ViewMoreText/ViewMoreText';
import Text from '@next-app/components/shared/PageContent/Text/Text';
import Modal from '@next-app/components/shared/PageContent/Modal/Modal';
import Link from '@next-app/components/shared/PageContent/Link/Link';
import JSON from '@next-app/components/shared/PageContent/JSON/JSON';
import Spacer from '@next-app/components/shared/PageContent/Spacer/Spacer';
import PageTitle from '@next-app/components/shared/PageContent/PageTitle/PageTitle';

interface ComponentsMap {
  [key: string]: React.FC<any> | ComponentType<any>;
}

export const COMPONENTS_MAP: ComponentsMap = {
  'PageContent-AccountMenu': AccountMenu,
  'PageContent-AccountMenuLink': AccountMenuLink,
  'PageContent-AllCategoryNavigationMenu': AllCategoryNavigationMenu,
  'PageContent-Banner': Banner,
  'CarouselContent-Media': Media,
  'PageContent-Chiclet': Chiclet,
  'PageContent-ChicletsContainer': ChicletsContainer,
  ContentSlot: ContentSlot,
  'PageContent-ContactForm': ContactForm,
  'PageContent-ContactFormField': ContactFormField,
  'PageContent-DynamicCategoriesNavigationMenu':
    DynamicCategoriesNavigationMenu,
  'PageContent-EyebrowMessage': EyebrowMessage,
  'PageContent-FeaturedSlot': FeaturedSlot,
  'PageContent-FooterCertification': FooterCertification,
  'PageContent-FooterLinkBlock': FooterLinkBlock,
  'PageContent-FooterLinkColumn': FooterLinkColumn,
  'PageContent-FullWidthImageWithButtons': FullWidthImageWithButtons,
  'PageContent-ImageRow': ImageRow,
  'PageContent-JSON': JSON,
  'PageContent-LakeshoreIcon': LakeshoreIcon,
  'PageContent-Link': Link,
  'PageContent-MLink': MLink,
  'PageContent-LinkFooter': LinkFooter,
  'PageContent-MainMenu': MainMenu,
  'PageContent-MenuTabSlot': MenuTabSlot,
  'PageContent-MenuTab': MenuTab,
  'PageContent-MenuGroup': MenuGroup,
  'PageContent-MenuGroupSlot': MenuGroupSlot,
  'PageContent-MenuItem': MenuItem,
  'PageContent-MenuLink': MenuLink,
  'PageContent-MicroBanner': MicroBanner,
  'PageContent-Modal': Modal,
  'PageContent-Navigation': Navigation,
  'PageContent-Button': PageContentButton,
  'Page-OneColumn': PageOneColumn,
  PageSlot: PageSlot,
  'PageContent-ProductRecs': ProductRecs,
  'PageContent-RowImage': RowImage,
  'PageContent-Section': Section,
  'PageContent-SectionHeader': SectionHeader,
  'PageContent-Slot': Slot,
  'PageContent-Search': Search,
  'PageContent-SectionHTMLTag': SectionHTMLTag,
  'PageContent-SectionFooter': SectionFooter,
  'PageContent-SocialIcons': SocialIcons,
  'PageContent-SubscribeForm': SubscribeForm,
  'PageContent-StrictCategoryListingTitle': StrictCategoryListingTitle,
  'PageContent-Text': Text,
  'PageContent-ViewMoreText': ViewMoreText,
  'PageContent-Spacer': Spacer,
  'PageContent-PageTitle': PageTitle,
};
